<template>
  <v-container fluid class="latest-articles">
    <v-container>
      <v-row align="center" justify="center" class="mt-6 mb-6">
        <span class="text-h4 text-primary font-weight-bold">
          News
        </span>
      </v-row>
      <v-tabs v-model="selectedTab" align-with-title background-color="#f7f9fc" class="mb-6">
        <v-tab v-for="(category, index) in categories" :key="index">{{ category }}</v-tab>
      </v-tabs>
      <v-row>
        <v-col cols="12" class="news-list">
          <v-divider></v-divider>
          <div v-for="(article, index) in filteredArticles" :key="index">
            <v-row class="mb-6 align-center">
              <v-col cols="2" class="text-left">
                <span class="text-overline font-weight-bold text-primary">{{ article.date }}</span>
              </v-col>
              <v-col cols="1">
                <v-divider vertical></v-divider>
              </v-col>
              <v-col cols="6">
                <div class="article-category font-weight-bold text-medium-emphasis">{{ article.category }}</div>
                <h3 class="font-weight-regular text-subtitle-1 text-medium-emphasis">{{ article.title }}</h3>
                <v-btn :href="article.url" target="_blank" color="primary" variant="text">Read Here</v-btn>
              </v-col>
              <v-col cols="3">
                <v-img :src="article.image" class="article-image"></v-img>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script setup>
import { ref, computed } from 'vue'

const categories = ['Latest', 'Newsletter', 'Article']
const selectedTab = ref(0)  
const articles = ref([
  { date: '13 October 2024', category: 'Article', title: "PREVENTS DISCOLORATION OF NYLON DENTAL APPLIANCES", image: "/img/orthofresh-logo.webp", url: "https://www3.braebon.com/uploads/Ortho_Fresh_Prevents_Discoloration_Of_Nylon_Dental_Appliances_Full_Length_ebcb2ac4ef.pdf" },
  { date: '9 October 2024', category: 'Newsletter', title: "ORTHOFRESH COLD WATER CLEANER IS BEST FOR DENTAL APPLIANCES", image: "/img/banner_background.webp", url: "https://www3.braebon.com/uploads/Ortho_Fresh_Cold_Water_Cleaner_Is_Best_for_Dental_Appliances_270d349692.pdf" },
])

const filteredArticles = computed(() => {
  const category = categories[selectedTab.value]
  return category === 'Latest' ? articles.value : articles.value.filter(article => article.category === category)
})
</script>

<style scoped>
.latest-articles {
  background-color: #fff;
}

.latest-articles h2 {
  font-weight: 600;
  color: #026597;
}

.article-category {
  color: #25356a;
  text-transform: uppercase;
}

.article-image {
  height: 100px;
  width: 100%;
  object-fit: cover;
}
</style>
