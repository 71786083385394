<template>
  <section id="about">
    <v-container fluid class="mb-n12">
      <v-row align="center" justify="center" class="mb-6">
        <v-col cols="10">
          <!-- details -->
          <v-row align="center" justify="center">
            <NuxtLazyHydrate when-idle>
              <v-col cols="12" class="mb-n8">
                <nuxt-img
                  src="/img/orthofresh-logo.webp"
                  format="webp"
                  quality="100"
                  :placeholder="[350, 155, 50, 1]"
                  width="350px"
                  alt="OrthoFresh Logo"
                  class="ml-n8"
                />
              </v-col>

              <v-col cols="12" class="mb-n4">
                <span class="text-h4 font-weight-light">
                  Available Now
                </span>
              </v-col>

              <v-col cols="12" md="7" class="d-flex align-center">
                <v-row>
                  <v-col cols="12" class="d-flex align-center">
                    <img
                      src="/img/global.svg"
                      style="max-width:150px"
                      class="mr-4"
                      alt="Global Icon"
                    />
                    <p class="text-justify aboutText">
                      People around the world use Ortho Fresh cold water cleaner because it is the simple, fast, effective way to clean dentures, night guards, splints, partial, and anti-snoring appliances.
                      Daily cleaning with Ortho Fresh will help maintain any dental appliance in a like new condition.
                    </p>
                  </v-col>

                  <!-- orthofresh image -->
                  <v-col cols="12" class="d-flex align-center">
                    <img
                      src="/img/medical.svg"
                      style="max-width:150px"
                      class="mr-4"
                      alt="Medical Icon"
                    />
                    <p class="text-justify aboutText">
                      Dental Hygienists across the country use Ortho Fresh to clean partials while performing routine cleanings.
                      Hygienists also recommend Ortho Fresh to orthodontic patients, to clean their retainers and splints.
                      Patients love the clean minty Ortho Fresh® flavor!
                    </p>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center">
                    <img
                      src="/img/clean.svg"
                      style="max-width:150px"
                      class="mr-4"
                      alt="Man Infront of Sink"
                    />
                    <p class="text-justify aboutText">
                      Some oral appliances can be damaged or distorted when they are cleaned or soaked in warm or hot water.
                      Ortho Fresh is specifically formulated to clean ALL oral appliances.
                    </p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="5" class="d-md-flex">
                <v-img
                  src="/img/orthofresh_transparent.webp"
                  class="d-block ml-auto mr-auto"
                  alt="OrthoFresh Professional Cold Water Dental Appliance Cleaner"
                  max-width="400px"
                />
              </v-col>
            </NuxtLazyHydrate>
          </v-row>

          <!-- video -->
          <v-row align="center" justify="center" class="mt-6 mb-6">
            <span class="text-h4 text-primary font-weight-bold">
              See
            </span>
            <span class="text-h4">&nbsp;it in&nbsp;</span>
            <span class="text-h4 font-weight-bold" style="color:#01bbd3">Action</span>
          </v-row>

          <v-row align="center" justify="center" class="mb-n12">
            <NuxtLazyHydrate when-visible>
              <client-only>
                <div class="iframe-container">
                  <iframe
                    class="responsive-iframe"
                    src="https://www.youtube.com/embed/meLzoQ4sCvI"
                    title="OrthoFresh Professional Cold Water Dental Appliance Cleaner"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </client-only>
            </NuxtLazyHydrate>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- articles -->
    <Articles />

    <v-container fluid class="border-waves-container">
      <div class="svg-border-waves">
        <img src="/img/borderWavesBlue.svg" alt="Border Shapes" />
      </div>
    </v-container>
  </section>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { width, smAndDown } = useDisplay()
</script>

<style scoped>
#about {
  background-color: #f4f4f4;
}
.border-waves-container {
  position: relative;
  z-index: 2;
  background-color: transparent;
}
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  z-index: 1;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 75%;
  padding-top: 56.25%;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 75%;
}
</style>
